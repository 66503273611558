<template>
  <div class="rating-review">
    <div class="rating-title">
      <h2>{{ $t.getTranslation('LBL_PRODUCT_RATINGS') }}</h2>
      <div class="icon-holder"><img class="icon-rating" loading="lazy" :src="require('@/assets/images/rating-star-small.png')" /></div>
    </div>
    <div class="rating-text">
      <h1>{{ Math.round(product.Rating * 10) / 10 }}<span> out of 5</span></h1>
      <div class="rate-star">
        <div style="display: flex; align-items: center; justify-content: center">
          <star-rating :increment="0.1" :star-size="20" :show-rating="false" :rating="product.Rating" :read-only="true"></star-rating>
        </div>
        <p>{{ Math.round(product.Rating * 10) / 10 }} {{ $t.getTranslation('LBL_TOTAL_RATINGS') }}</p>
      </div>
    </div>
  </div>

  <div class="rating-review" v-if="list.length > 0">
    <div class="rating-title">
      <h2>{{ $t.getTranslation('LBL_CUSTOMER_COMMENTS') }}</h2>
      <div class="icon-holder"><img class="icon-rating" loading="lazy" :src="require('@/assets/images/rating-comment-small.png')" /></div>
    </div>
    <div class="rating-filter">
      <h3 style="color: #b7b6b6; font-weight: normal">{{ totalRating }} Reviews</h3>
      <!-- <f7-list inline-labels no-hairlines-md>
        <f7-list-input class="review-filter" :label="$t.getTranslation('LBL_FILTER')" type="select" v-model:value="formData.Rating" @change="filter">
          <option value="0">{{ $t.getTranslation('LBL_ALL') }}</option>
          <option value="1">1 {{ $t.getTranslation('LBL_STARS') }}</option>
          <option value="2">2 {{ $t.getTranslation('LBL_STARS') }}</option>
          <option value="3">3 {{ $t.getTranslation('LBL_STARS') }}</option>
          <option value="4">4 {{ $t.getTranslation('LBL_STARS') }}</option>
          <option value="5">5 {{ $t.getTranslation('LBL_STARS') }}</option>
        </f7-list-input>
      </f7-list> -->
    </div>
    <div class="comment-section" v-for="review in list" :key="'review_' + review.ProductReviewKey">
      <div class="comment-header">
        <div class="comment-name">
          <h2>{{ review.DisplayName }}</h2>
          <div>
            <star-rating :increment="0.1" :star-size="14" :show-rating="false" :rating="review.Rating" :read-only="true"></star-rating>
          </div>
        </div>
        <div class="comment-date">
          <p style="color: #b7b6b6">{{ $h.formatTimeAgo(review.LastCreated) }}</p>
        </div>
      </div>
      <div class="comment-body">
        <p>{{ review.Content }}</p>
      </div>
    </div>
    <div class="comment-footer">
      <div v-if="remainingPage > 1" @click="loadMore">
        <h3>{{ $t.getTranslation('LBL_LOAD_MORE') }}</h3>
      </div>
    </div>
  </div>
</template>
<script>
import { defineComponent, ref, onMounted, inject, computed, reactive, watch, defineAsyncComponent } from 'vue'
import { get } from '@/utils/axios'
import { helpers } from '@/utils/helpers.js'
import { useStore } from '@/store'

// import StarRating from '@/components/rating/star-rating.vue'

const StarRating = defineAsyncComponent(() => import(/* webpackChunkName: "star-rating" */ /* webpackMode: "lazy" */ '@/components/rating/star-rating.vue'))

export default defineComponent({
  name: 'ProductRatingComponent',
  components: { StarRating },
  props: { product: Object },
  setup(props) {
    const isGettingProd = ref(true)
    const store = useStore()

    const $t = inject('$translation')
    const languageCode = computed(() => store.getters['translation/getLanguage'])
    const rating = ref(0)
    const totalRating = ref(0)
    const page = ref(1)
    const size = ref(5)
    const list = ref([])
    const remainingPage = ref(0)
    const isLoading = ref(false)
    const filterSelection = ref([])
    const filterValue = ref(null)
    const formData = reactive({
      Rating: '0'
    })

    if (props.product.Rating <= 0) {
      rating.value = 4.5
    } else {
      rating.value = props.product.Rating
    }

    const getReviews = async () => {
      isLoading.value = true
      let ret = await get('/mobile/product/review/list', {
        ProductId: props.product.ProductId,
        page: page.value,
        size: size.value,
        Ratings: filterValue.value,
        LanguageCode: languageCode.value
      })
      isLoading.value = false

      if (ret?.data) {
        for (let index = 0; index < ret.data.length; index++) {
          const element = ret.data[index]
          let isPrivate = element.IsPrivate
          let name = $t.getTranslation('LBL_ANONYMOUS')
          if (!isPrivate && !helpers.isBlank(element.FirstName) && !helpers.isBlank(element.LastName)) {
            name = `${element.FirstName} ${element.LastName}`
          } else if (!isPrivate && helpers.isBlank(element.FirstName) && helpers.isBlank(element.LastName)) {
            name = element.UserName || element.Email
          }

          element.DisplayName = name
          list.value.push(element)
        }
      }

      totalRating.value = ret.totalData
      remainingPage.value = ret.lastPage
    }

    const loadMore = () => {
      if (isLoading.value) {
        return
      }
      if (remainingPage.value > 1) {
        //recall the function
        page.value += 1
        getReviews()
      }
    }

    const filter = () => {
      let Rating = parseInt(formData.Rating)
      if (Rating > 0) {
        filterValue.value = Rating
      } else {
        filterValue.value = null
      }
      page.value = 1
      getReviews()
      return
    }

    onMounted(() => {
      getReviews()
    })

    return { isGettingProd, rating, list, totalRating, isLoading, filterSelection, formData, filter }
  }
})
</script>

<style type="text/css">
.comment-footer {
  display: flex;
  justify-content: center;
  cursor: pointer;
  padding: 20px 0;
}
.comment-section {
  padding: 20px;
  border-bottom: 1px solid #e0e0e0;
}
.comment-body {
  text-align: left;
}
.comment-header {
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
}
.comment-name {
  display: flex;
  flex-direction: column;
}
.comment-header h2 {
  font-size: 16px;
}

.rating-filter .list .item-title {
  font-weight: 700;
  font-size: 16px;
  color: black;
}
.prod-details-field {
  padding: 10px;
  padding-left: 0px;
  border-bottom: 1px solid #e0e0e0;
}

.prod-details-field:last-child {
  border: none;
}

.prod-details-field label {
  font-weight: bold;
  margin-right: 20px;
}
.icon-holder {
  width: 35px;
  height: 35px;
}
.icon-rating {
  width: 100%;
}
.rating-title {
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  justify-content: space-between;
  align-items: center;
  width: calc(100% - 40px);
  background-color: #d9ecfb;
  padding: 0 20px;
  height: 50px;
  font-size: 12px;
}
.rating-filter {
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  justify-content: space-between;
  align-items: center;
  width: calc(100% - 40px);
  padding: 0 20px;
  height: 50px;
  font-size: 12px;
  border: 1px solid #f7f7f7;
}
.rating-review {
  margin: 20px 0px;
  border: 1px solid #f7f7f7;
  box-shadow: 0 0 1px 0 rgb(0 0 0 / 10%);
  text-align: center;
  border-radius: 5px;
}
.rating-review p {
  margin: 0px;
}
.rate-star {
  display: block;
}
.rating-text {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 20px;
}
.rating-text h1 {
  font-size: 26px;
}

.rating-text span {
  font-size: 14px;
  font-weight: normal;
}
.review-filter {
  width: 190px;
  height: 50px;
  font-size: 14px;
  font-weight: normal;
}
.rating-filter .list {
  width: 45%;
}
.comment-date h3 {
  color: #b7b6b6;
  font-weight: normal;
}
.md .item-input:not(.item-input-outline) .item-input-wrap:after {
  display: none;
}
</style>
